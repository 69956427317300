@import '../../styles/customMediaQueries.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--colorFail);
}

.aside {
  box-shadow: none;
}

.asideContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--colorGrey100);

  @media (--viewportLarge) {
    flex-direction: column;
    border-bottom: none;
  }
  @media (max-width: 1023px) {
    align-items: flex-start;
    padding-bottom: 30px;
    flex-direction: column;
    border-bottom: 0;
  }
}

.avatar {
  margin: 0 96px 26px 0;
  flex-shrink: 0;

  @media (--viewportLarge) {
    /* margin: 0 96px 48px 0; */
  }
  @media (max-width: 1199px) {
    margin: 0 45px 26px 0;
  }
  @media (max-width: 1023px) {
    margin: 0 30px 0 0;
  }
  @media (max-width: 575px) {
    width: 60px;
    height: 60px;
    margin: 0 10px 0 0;
  }
}

.mobileHeading {
  flex-shrink: 0;
  margin: 60px 0 0 0;

  @media (--viewportMedium) {
    margin: 49px 0 0 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
  @media (max-width: 1023px) {
    margin: 10px 0 15px  !important;
    padding: 0 30px 0 0;
  }
  @media (max-width: 575px) {
    padding: 0 10px 0 0;
  }
}

.editLinkMobile {
  margin-top: 17px;

  /* Pull the link to the end of the container */
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
  @media (--viewportLarge) {
    display: none;
  }
  @media (max-width: 1023px) {
    margin-left: 0;
    margin-top: 0;
  }
  & > span {
    font-size: 20px;
    font-weight: 600;
  }
  &:hover {
    text-underline-offset: 0.3em;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
    padding: 6px 0 2px 0;
  }
  & > span {
    font-size: 20px;
    font-weight: 600;
  }
}

.desktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 0 24px 0;
  }
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 5px 0 24px 0;
  /* word-break: break-all; */
  word-break:inherit;
  text-align: justify;
  @media (--viewportMedium) {
    margin: 4px 0 51px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 56px 0;
    /* max-width: 600px; */
  }
  @media (max-width: 1023px) {
    margin: -50px 0 40px 0;
  }
}

.listingsContainer {
  composes: clearfix from global;
  /* border-top: 1px solid var(--colorGrey100); */
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
  /* @media (max-width: 1024px) {
    border-top: 0;
  } */
}

.listingsTitle {
  color: var(--colorGrey700);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }
  @media (--viewportLarge) {
    margin-top: 56px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    border-top: 1px solid var(--colorGrey100);
  }
}

.listings {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.listing {
  width: 100%;

  /* Single column in mobile */
  margin-top: 34px;
  &:first-of-type {
    margin-top: 18px;
  }

  @media (--viewportMedium) {
    /* Two columns in desktop */
    width: calc(50% - 12px);
    margin-bottom: 36px;

    /* Horizontal space */
    &:nth-of-type(odd) {
      margin-right: 12px;
    }
    &:nth-of-type(even) {
      margin-left: 12px;
    }

    /* Vertical space */
    margin-top: 0;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-top: 20px;
    }
  }
}

.mobileReviewsTitle {
  margin: 24px 0 26px 0;
}

.mobileReviews {
  /* border-top: 1px solid var(--colorGrey100); */
  margin-bottom: 36px;
}

.desktopReviews {
  /* border-top: 1px solid var(--colorGrey100); */

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.desktopReviewsWrapper {
  /* max-width: 600px; */
  margin-top: 28px;
}

.desktopReviewsTitle {
  composes: textLarge from global;
  margin-bottom: 0;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}

.profileUrl {
  margin-bottom: 20px;
  @media (max-width: 1023px) {
    margin-bottom: 10px;
  }
}
.profilepagewrap {
  & > div {
    @media (max-width: 1023px) {
      display: block !important;
    }
    & > div:nth-child(2) {
      @media (min-width: 1024px) {
        max-width: 100vw;
        justify-content: flex-start;
      }
      @media (min-width: 1920px) {
        /* max-width:1120px; */

        margin: 0 auto;
      }
      & > aside {
        padding: 48px 0 82px 36px !important;
        /* width: 20%; */
        background-color: #fffbf2 !important;
        @media (min-width: 1024px) {
          max-width: 205px;
        }
        flex: 0 0 20%;
        /* @media(max-width:1199px){
          padding: 48px 0 82px 36px !important;
        } */
        @media (max-width: 1023px) {
          width: 100%;
          flex: 0 0 100%;
          padding: 20px 15px !important;
        }
      }
      & > main {
        padding: 48px 36px 82px 0 !important;
        width: 80%;
        flex: 0 0 80%;
        border-left: 0;
        background-color:#fffbf2 !important;
        @media (max-width: 1023px) {
          width: 100%;
          flex: 0 0 100%;
          padding: 20px 15px !important;
        }
      }
    }
  }
}
.desktopProfileUrl {
  @media (max-width: 1023px) {
    display: none;
  }
}
.MobProfileUrl {
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
}
.MobHeadWrap {
  display: flex;
  gap: 0;
  flex-direction: column;
}
